<template>
  <div class="main-wrapper dark-bg">

        <Header></Header>

        <Slider></Slider>

        <Offcanvas></Offcanvas>

        <BrandLogo></BrandLogo>

        <AboutSection></AboutSection>
        
        <Funfact></Funfact>

        <Workgallery></Workgallery>

        <BlogArticle></BlogArticle>

        <Testimonial></Testimonial>

        <Footer></Footer>
        
  </div>
</template>

<script>

import Header from '@/components/Header'
import Slider from '@/components/Slider'
import Offcanvas from '@/components/Offcanvas'
import BrandLogo from '@/components/BrandLogo'
import AboutSection from '@/components/AboutSection'
import Funfact from '@/components/Funfact'
import Workgallery from '@/components/Workgallery'
import BlogArticle from '@/components/BlogArticle'
import Testimonial from '@/components/Testimonial'
import Footer from '@/components/Footer'

export default {
  name: 'HomePage',
  components: {
    Header,
    Slider,
    Offcanvas,
    BrandLogo,
    AboutSection,
    Funfact,
    Workgallery,
    BlogArticle,
    Testimonial,
    Footer
  }
}
</script>
