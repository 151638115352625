<template>
    <div class="section overflow-hidden bg-dark">
        <!-- About Start -->
        <div class="about-section section-padding mt-n3 mb-n1">
            <!-- <div class="about-images" :style="{backgroundImage: `url(${ aboutBg })`}"></div> -->

            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="about-img" :style="{backgroundImage: `url(${ aboutBg })`}"></div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about-content wow fadeInRight" data-wow-delay="0.5s" data-wow-duration="2s">
                            <h3 class="title">{{ aboutTitle }}</h3>
                            <p class="about-text">Founded in London in 2001, Nam faucibus varius volutpat. Donec vitae ligula faucibus, molestie dolor vitae, consequat nisl.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc volutpat enim sit amet purus vestibulum, sit amet bibendum tellus tempor. Duis sapien sapien, egestas id sapien et, mollis ultricies nisi. Integer id est at nunc iaculis tincidunt bibendum sed lacus.</p>
                            <p>Sed cursus, nibh quis eleifend pharetra, dolor orci tempor eros, vel rutrum purus ligula vel ipsum</p>
                            <!-- <img class="signechar" src="/images/signechar.png" alt="">
                            <h6 class="name">Daniel Jr</h6> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- About End -->
    </div>
</template>
<script>
export default {
    name: 'about-section',
    data() {
        return { 
            aboutBg: "/images/about-img.jpg",
            aboutTitle: 'About Us',
        }
    }
}
</script>
